import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, TextField, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
    margin: '0 auto',
  },
  input: {
    margin: theme.spacing(1),
    width: '100%',
  },
  button: {
    margin: theme.spacing(2),
    width: '100%',
  },
  privacyLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
    textDecoration: 'none',
  },
  checkbox: {
    '&.Mui-checked': {
      color: 'black',
    },
  },
}));

export default function ConsentForm({ onSubmit, groupId }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    fullName: '',
    country: '',
    city: '',
    cardNumber: '',
    date: '',
    consent: false,
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    const textPattern = /^[A-Za-z\s]+$/;

    if (!textPattern.test(formData.fullName)) {
      newErrors.fullName = 'Full Name can only contain letters and spaces';
    }
    if (!textPattern.test(formData.country)) {
      newErrors.country = 'Country of Citizenship can only contain letters and spaces';
    }
    if (!textPattern.test(formData.city)) {
      newErrors.city = 'City can only contain letters and spaces';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      onSubmit(formData);
    }
  };

  const isFormValid = () => {
    return (
      formData.fullName &&
      formData.country &&
      formData.city &&
      formData.cardNumber &&
      formData.date &&
      formData.consent
    );
  };

  const getPrivacyPolicyLink = () => {
    if (groupId === 'TP-latam-colombia') {
      return '/privacy_policy_colombia.pdf';
    } else if (groupId === 'TP-latam-peru') {
      return '/privacy_policy_peru.pdf';
    } else {
      return '/privacy_policy.pdf';
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography variant="h6">Consent Form</Typography>
      <TextField
        className={classes.input}
        label="Full Legal Name"
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        error={!!errors.fullName}
        helperText={errors.fullName}
        required
      />
      <TextField
        className={classes.input}
        label="Country of Citizenship"
        name="country"
        value={formData.country}
        onChange={handleChange}
        error={!!errors.country}
        helperText={errors.country}
        required
      />
      <TextField
        className={classes.input}
        label="City"
        name="city"
        value={formData.city}
        onChange={handleChange}
        error={!!errors.city}
        helperText={errors.city}
        required
      />
      <TextField
        className={classes.input}
        label="Citizenship Card Number"
        name="cardNumber"
        value={formData.cardNumber}
        onChange={handleChange}
        required
      />
      <TextField
        className={classes.input}
        label="Date"
        name="date"
        type="date"
        value={formData.date}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
        required
      />
      <FormControlLabel
        control={
          <Checkbox
            name="consent"
            checked={formData.consent}
            onChange={handleChange}
            required
            className={classes.checkbox}
          />
        }
        label="I confirm that I have reviewed the privacy policy and consent to its terms and conditions."
      />
      <a href={getPrivacyPolicyLink()} target="_blank" rel="noopener noreferrer" className={classes.privacyLink}>
        <img src="/privacy_policy.svg" alt="Privacy Icon" style={{ marginRight: '5px' }} />
        View Privacy Policy
      </a>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={!isFormValid()}
      >
        Next
      </Button>
    </form>
  );
}
